import React from 'react';

import useAuth from '../../hooks/useAuth';
import LogoElte from '../../assets/images/LogoElte2.svg';

import { Button, Grid,  } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';

function ViewNavBar() {
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  
  
    const { t } = useTranslation();
    function logout() {
        setAuth(false);
    }

   

    
    return (
        <>
            
            <div className='navBarElte'>
                <Grid>
                    <Grid.Row columns={2} verticalAlign='middle'>
                        <Grid.Column textAlign='left'>
                            <div style={{ width: 150, height: 60 }}><img src={LogoElte} alt="LogoElte" /></div>
                            <div className={"appname"} style={{ color: "#fff" }} >{t("strona_logowania.nazwa_aplikacji")}</div>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <Button onClick={() => logout()} color='orange'>{t("tablica.przycisk_wyloguj")}</Button>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>

               
            </div>
        </>
    )
}

export default ViewNavBar;