import React from 'react';

const AuthContext = React.createContext({
    isAuthenticated: false,
    userName:"",
    showModalError:null,
    token: null,
    login:() => {},
    logout:() => {},
    changeUserName:(val) => {},
    changeModalError:(val) => {},
    changeToken:(val) => {},
});

export default AuthContext;