
import dateFormat from 'dateformat';
import {  Icon } from 'semantic-ui-react';


export function changeFormatDate(mydate){
     
    mydate ? mydate=dateFormat(mydate,"yyyy-mm-dd") : mydate=""
    return mydate;
    
}
export function changeFormatDateTime(mydate){
     
    mydate ? mydate=dateFormat(mydate,"yyyy-mm-dd'T'HH:MM:ss") : mydate=""
    return mydate;
    
}

export function changeFormatDateTimeDB(mydate){
     
    mydate ? mydate=dateFormat(mydate,"yyyy-mm-dd HH:MM:ss") : mydate=""
    return mydate;
    
}

export function getDateTimeE(co,mydate){
 if(co==="TIME"){
   return dateFormat(mydate,"HH:MM:ss")
 }else{
   return dateFormat(mydate,"yyyy-mm-dd")
 }
}

export function checkTimeVal(DateFrom, DateTo) {
 
    console.log(changeFormatDateTimeDB(Date.parse(DateTo)),changeFormatDateTimeDB(Date.parse(DateFrom)),Date.parse(DateTo)-Date.parse(DateFrom))
    if ((Date.parse(DateTo) - Date.parse(DateFrom)) <= 0) {
        return true;
    } else {
        return false;
    }

}

export function checkTimeValServiceNotification(DateFrom, DateTo) {
  if (Math.abs((Date.parse(DateFrom) - Date.parse(DateTo))) > (86400000*2)) {
      return true;
  } else {
      return false;
  }

}

export function SCezarencrypt(text, shift = 7) {
    var result = "";


    //loop through each caharacter in the text
    for (var i = 0; i < text.length; i++) {

        //get the character code of each letter
        var c = text.charCodeAt(i);

        // handle uppercase letters
        if (c >= 65 && c <= 90) {
            result += String.fromCharCode((c - 65 + shift) % 26 + 65);

            // handle lowercase letters
        } else if (c >= 97 && c <= 122) {
            result += String.fromCharCode((c - 97 + shift) % 26 + 97);

            // its not a letter, let it through
        } else {
            result += text.charAt(i);
        }
    }

    return result;
}

export function SCezardecrypt(text, shift = 7) {
    var result = "";
    shift = (26 - shift) % 26;
    result = SCezarencrypt(text, shift);
    return result;
}

export function loadingShow() {
  return  <Icon loading name='spinner' size='large' />
}