import React, { useEffect, useState } from "react";

import i18next from "i18next";
import { Dropdown } from "semantic-ui-react";



const LanguageSelect = () => {

  const [selected, setselected] = useState()

  function getLang() {
    try {
      console.log(window.location.host)
      if (window.location.host.includes("e-invoice")) {
        document.title = "INVOICE SYSTEM - ELTE GROUP";
        return "en"
      } else {
        document.title = "SYSTEM FAKTUR - ELTE GROUP";
        return "pl"
      }
    } catch (e) {
      document.title = "SYSTEM FAKTUR - ELTE GROUP";
      return "pl"
    }
  }

  useEffect(() => {
    let tmpselected = getLang();

    i18next.changeLanguage(tmpselected);
    setselected(tmpselected)
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const languages = [
    {
      key: 'pl',
      text: '',
      value: 'pl',
      flag: { name: "pl" },
    },
    {
      key: 'en',
      text: '',
      value: 'en',
      flag: { name: "gb" },
    }
  ]

  const handleClick = (e, { value }) => {
    setselected(value)
    i18next.changeLanguage(value);
  };

  return (
    <div style={{ marginLeft: 10, marginRight: 3 }}>

      <Dropdown
        inline
        options={languages}
        value={selected}
        onChange={handleClick}
      />

    </div>
  );
};

export default LanguageSelect;  