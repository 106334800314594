import './App.css';
import 'semantic-ui-css/semantic.min.css';
import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Content from './layout/Content/Content';
import React, { useReducer, useEffect } from 'react';
import AuthContext from './context/authContext';
import { CookiesProvider } from 'react-cookie';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Header, Icon, Segment } from 'semantic-ui-react';
import imgelte from './assets/images/LogoElte.svg';
import "./i18nextInit";

const reducer = (state, action) => {
  switch (action.type) {
    case 'set-isAuthenticated': return { ...state, isAuthenticated: action.isAuthenticated };
    case 'set-UserName': return { ...state, username: action.username };
    case 'set-ModalError': return { ...state, showModalError: action.showModalError};
    case 'set-Token': return { ...state, token: action.token };
    case 'login': return { ...state, isAuthenticated: true };
    case 'logout': return { ...state, isAuthenticated: false };
    
    default: console.log('Sorry');
  }
  return state;
}

const initState = {
  isAuthenticated: false,
  username: "",
  showModalError:null,
  token: null,
};

function ErrorFallbackApp({ error, resetErrorBoundary }) {
  return (
    <Segment placeholder style={{ height: "100vh" }}>

      <Header icon>

        <Icon name='warning sign' />
        Napotkaliśmy problem - odśwież stronę.

        <Header.Subheader>
          Jeśli ten problem będzie się powtarzać, skontaktuj się z administratorem systemu aby uzyskać więcej informacji.
        </Header.Subheader>
        <br />
        <img src={imgelte} alt="ELTE GROUP" style={{ width: 120, margin: 5 }} />
      </Header>

    </Segment>
  )
}


function App() {

  const [state, dispatch] = useReducer(reducer, initState);
  useEffect(() => {

  }, []);


  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackApp}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}>

      <CookiesProvider>
        <Router >
          <AuthContext.Provider value={{
            isAuthenticated: state.isAuthenticated,
            userName: state.username,
            showModalError: state.showModalError,
            token: state.token,
            login: () => dispatch({ type: 'login' }),
            logout: () => dispatch({ type: 'logout' }),
            changeUserName: (val) => dispatch({ type: 'set-UserName', username: val }),
            changeModalError: (val) => dispatch({ type: 'set-ModalError', showModalError: val }),
            changeToken: (val) => dispatch({ type: 'set-Token', token: val }),
          }}>

            <Content />

          </AuthContext.Provider>
        </Router>
      </CookiesProvider>
    </ErrorBoundary>
  );
}

export default App;
