import { useContext } from "react";
import AuthContext from "../context/authContext";

export default function useAuth() {
    const authContext = useContext(AuthContext);
    const auth = authContext.isAuthenticated;
    const setAuth = (isAuthenticated, tokenData = null) => {
        if (isAuthenticated) {
           
            if (tokenData) {
                window.sessionStorage.setItem('token', tokenData);
            }

            authContext.login();
        } else {
         
            window.sessionStorage.clear();
            setTimeout(() => {
                authContext.logout();
                authContext.changeToken(null);
            }, 1000);


        }

    }
    return [auth, setAuth]
}