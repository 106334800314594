import React from 'react'
import { Button, Header, Image } from 'semantic-ui-react';
import LogoEltePNG from '../../assets/images/LogoElte.svg';
import { useHistory } from "react-router-dom";

function NotFound() {
  let history = useHistory();
  return (
    <>
  
      
    <div className="errorS">
      
      <div className="ui column center aligned">
      <Header  style={{fontSize:60}}>
        BŁĄD 404
      <Header.Subheader style={{fontSize:30}}>
   UPS! NIE UDAŁO SIĘ ZNALEŹĆ TEJ STRONY!
  
  </Header.Subheader>
  <Header.Subheader>
    <br/>
  Wróć na stronę główną lub skontaktuj się z nami. Pomożemy.
  </Header.Subheader>
      </Header>
      <Image style={{ padding: 10 }} centered rounded size='medium' src={LogoEltePNG} alt="logo" />
      <br/>
      <a href="/"><Button size='large' basic>STRONA GŁOWNA</Button></a><Button onClick={()=>history.go(-2)} size='large' basic>POPRZEDNIA STRONA</Button>
      </div>
    </div>
    </>
  );
}

export default NotFound;