import axios from '../axios';


export function ConnectWS(m, method, tokendata, params, callback) {

  // const data = {};
  // const paramstmp = new URLSearchParams(params).toString();
  // method=method+"?"+paramstmp;


  let start = new Date().getTime();
  if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
    console.log("PYTAM - " + start + " | T:" + tokendata + " | ", m, method, JSON.stringify(params))
  }
  if (tokendata) {
    let tokendata1 = "Bearer " + tokendata;
    axios.defaults.headers.common['Authorization'] = tokendata1;
  } else {
    axios.defaults.headers.common['Authorization'] = null;
  }


  let headerm = null
  if (m === "GET") {
    headerm = {
      'Content-Type': 'application/json'
    }
  } headerm = {
    'Content-Type': 'application/json'
  }

  axios({
    method: m, url: method, data: params, params: m === "GET" ? params : null,
    headers: headerm, responseType: m === "GET" ? "blob" : "json"
  }).then(response => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      let end = new Date().getTime();
      let time = end - start;
      console.log("ODP - " + start + " Czas: " + msToTime(time) + " | ", method, response)
    }

    callback(response);
  }).catch(err => {
    // brak autoryzacji wylogowanie
    let errString = err + "";
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("ERR", err, errString)
    }
    if (err.response && err.response.status === 401) {
      callback({ error: err.response.data.message }, 1);
    } else {
      callback("", 2);
    }

    /*
    if (errString.indexOf("403") > 0) {
      callback("BRAK UPRAWNIEŃ DO API E403", 2);
    } else if (errString.indexOf("404") > 0) {
      callback("BRAK METODY W API E404", 2);

    } else if (errString.indexOf("500") > 0) {
      callback("BŁAD w API E500", 2);

    } else if (errString.indexOf("Error") > 0) {
      callback("E500", 2);
    }
    else if (typeof err.response === "undefined") {
      callback("BRAK ODPOWIEDZ Z SERWERA", 2);
    }
    else if (err.response.status === 401) {
      callback({error:err.response.data.message}, 1);
    } else if (err.response.status === 400) {
      //{error: 'Jakieś ostrzeżenie', sqlError: false, type: 'info'}
      try {
        console.log(err.response.data)
        if (err.response.data.sqlError) {
          callback("Wystąpił błąd bazy danych, skontaktuj się z administratorem", 103);
        } else if (err.response.data.type === "info") {
          callback(err.response.data.message, 101);
        } else if (err.response.data.type === "warning") {
          callback(err.response.data.message, 102);
        } else if (err.response.data.type === "error") {
          callback(err.response.data.message, 103);
        } else {
          callback("Wystąpił błąd: B1, skontaktuj się z administratorem");
        }
      } catch (e) {
        console.log(e)
        callback("Wystąpił błąd: B2, skontaktuj się z administratorem");
      }
    } else {
      callback("Wystąpił błąd: B3, ponów próbę");
    }
      */

  })
}



function msToTime(duration) {
  var milliseconds = parseInt((duration % 1000))
    , seconds = parseInt((duration / 1000) % 60)
    , minutes = parseInt((duration / (1000 * 60)) % 60)
    , hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
}



